import React from 'react'
import { Container, VideoPlayer } from './YoutubeVideo.styled'

const YoutubeVideo = props => {
  var videoSrc = `https://www.youtube.com/embed/${props.video}?autoplay=${
    props.autoplay
  }&rel=${props.rel}&modestbranding=${props.modest}`
  return (
    <Container>
      <VideoPlayer
        src={videoSrc}
        type="text/html"
        frameBorder="0"
        allowFullScreen
      />
    </Container>
  )
}

export default YoutubeVideo
