import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  height: 300px;
  position: relative;
  box-shadow: var(--shadow-dark);
`

const VideoPlayer = styled.iframe`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`

export { Container, VideoPlayer }
