import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { BP_MEDIUM, BP_SMALL } from '../../styles/theme'

const ArticleCardCell = styled.div`
  width: 50%;
  max-width: 325px;
  height: 450px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  display: flex;
  background-color: ${props => props.theme.backgroundLight};
  flex-direction: column;
  box-shadow: var(--shadow-dark);

  ${props =>
    props.horizontal &&
    css`
      max-width: 500px;
      flex-direction: row;
      height: 200px;
    `};
  @media only screen and (max-width: ${BP_MEDIUM}) {
    width: 95%;
  }
`
const CardImage = styled(Img)`
  width: 100%;

  ${props =>
    props.horizontal &&
    css`
      width: 25%;
      min-width: 200px;
      @media only screen and (max-width: ${BP_SMALL}) {
        min-width: 100px;
      }
    `}
`

const Description = styled.div`
  padding: 1rem 2rem;
  overflow: hidden;
  & > div {
    display: -webkit-box;
    -webkit-line-clamp: ${props => (props.horizontal ? 3 : 6)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: ${props => (props.horizontal ? '85px' : '150px')};
    @media only screen and (max-width: ${BP_MEDIUM}) {
      -webkit-line-clamp: ${props => (props.horizontal ? 7 : 11)};
      max-height: ${props => (props.horizontal ? '130px' : '200px')};
    }
  }
`
const CustomLink = styled.a`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ArticleTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  & > a {
    text-decoration: none;
    color: ${props => props.theme.primary};
    &:hover {
      color: ${props => props.theme.primaryLight};
    }
  }
`

export { ArticleCardCell, Description, ArticleTitle, CardImage, CustomLink }
