export const bookItems = [
  {
    key: 'book1',
    href:
      'https://www.amazon.com.mx/Hormiga-Culta-Javier-Arreguin-Ruiz-ebook/dp/B08CMRVD8G/ref=sr_1_6?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PQLSTCER2XMV&dchild=1&keywords=javier+arreguin+ruiz&qid=1601330240&sprefix=javier+arreguin+%2Cstripbooks%2C472&sr=8-6',
  },
  {
    key: 'book2',
    href:
      'https://www.amazon.com.mx/vistazo-los-impuestos-Am%C3%A9rica-Latina/dp/B089TS2FPX/ref=sr_1_8?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PQLSTCER2XMV&dchild=1&keywords=javier+arreguin+ruiz&qid=1601330240&sprefix=javier+arreguin+%2Cstripbooks%2C472&sr=8-8',
  },
  {
    key: 'book3',
    href:
      'https://www.amazon.com.mx/Crecer-M%C3%A9xico-invertir-tener-mexicano-ebook/dp/B08CMNMHNJ/ref=sr_1_3?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PQLSTCER2XMV&dchild=1&keywords=javier+arreguin+ruiz&qid=1601330240&sprefix=javier+arreguin+%2Cstripbooks%2C472&sr=8-3',
  },
  {
    key: 'book4',
    href:
      'https://www.amazon.com.mx/Gesti%C3%B3n-Profesional-MIPYMES-Javier-Arreguin-ebook/dp/B088MM4ZTC/ref=sr_1_2?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PQLSTCER2XMV&dchild=1&keywords=javier+arreguin+ruiz&qid=1601330240&sprefix=javier+arreguin+%2Cstripbooks%2C472&sr=8-2',
  },
  {
    key: 'book5',
    href:
      'https://www.amazon.com.mx/Marketing-digital-MiPymes-Javier-Arreguin-ebook/dp/B089QGNFBB/ref=sr_1_7?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PQLSTCER2XMV&dchild=1&keywords=javier+arreguin+ruiz&qid=1601330240&sprefix=javier+arreguin+%2Cstripbooks%2C472&sr=8-7',
  },
  {
    key: 'book6',
    href:
      'https://www.amazon.com.mx/50-ideas-negocio-post-COVID-19-ebook/dp/B08CTFVQ97/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PQLSTCER2XMV&dchild=1&keywords=javier+arreguin+ruiz&qid=1601330227&sprefix=javier+arreguin+%2Cstripbooks%2C472&sr=8-1',
  },
  {
    key: 'book7',
    href:
      'https://www.amazon.com.mx/Emprender-En-Crisis-Innovacion-Pandemia-ebook/dp/B088NRHPV4/ref=sr_1_5?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PQLSTCER2XMV&dchild=1&keywords=javier+arreguin+ruiz&qid=1601330240&sprefix=javier+arreguin+%2Cstripbooks%2C472&sr=8-5',
  },
  {
    key: 'book8',
    href:
      'https://www.amazon.com.mx/Instinto-Emprendedor-para-sobrevivir-Crisis-ebook/dp/B088MLBZQN/ref=sr_1_9?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PQLSTCER2XMV&dchild=1&keywords=javier+arreguin+ruiz&qid=1601330240&sprefix=javier+arreguin+%2Cstripbooks%2C472&sr=8-9',
  },
]
