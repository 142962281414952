import styled from 'styled-components'

const CarouselStyled = styled.div`
  position: relative;
  &:focus {
    outline: none;
  }
`
const CarouselTransCell = styled.div`
  width: 25%;
  max-height: 105px;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
`

const ImageLazyLoad = styled.img`
  transition: opacity 0.3s;
  opacity: 0;
  display: block;
  max-height: 100%;
  margin: auto;
  max-width: 100%;
  padding: 2px;
  &.flickity-lazyloaded,
  &.flickity-lazyerror {
    opacity: 1;
  }
`

export { CarouselTransCell, CarouselStyled, ImageLazyLoad }
