import styled from 'styled-components'
import Img from 'gatsby-image'

const CustomImage = styled(Img)`
  width: 100%;
  max-height: 180px;
  transition: width 300ms ease;
`

const ContainerImage = styled.div`
  position: relative;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
  &:hover > ${CustomImage} {
    width: 120%;
  }
`

const ContentDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 68px;
  width: 100%;
  padding: 5px 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
`

const TitleNew = styled.h4`
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export { ContainerImage, ContentDetails, CustomImage, TitleNew }
