import React from 'react'
import { Link } from 'gatsby';
import {
  ArticleCardCell,
  Description,
  ArticleTitle,
  CardImage,
  CustomLink,
} from './ArticlePreview.styled.js'

const ArticlePreview = ({ pathname, article, horizontal = false }) => {
  const prefix = pathname.substring(0, pathname.lastIndexOf('/'))
  return (
    <ArticleCardCell horizontal={horizontal}>
      <CardImage
        alt=""
        fluid={article.heroImage.fluid}
        horizontal={horizontal}
      />
      <Description horizontal={horizontal}>
        <ArticleTitle>
          {prefix !== "/noticias" ? 
            <CustomLink to={`${prefix}/${article.slug}`} as={Link}>
              {article.title}
            </CustomLink>
          : 
            <CustomLink href={`https://asnews.mx${prefix}/${article.slug}`} target="_blank">
              {article.title}
            </CustomLink>
          }
        </ArticleTitle>
        <small>{article.publishDate}</small>
        <div
          dangerouslySetInnerHTML={{
            __html: article.description.childMarkdownRemark.html,
          }}
        />
      </Description>
    </ArticleCardCell>
  )
}

export default ArticlePreview
