import React, { useState } from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'

import { NotificationContainer } from 'react-notifications'
import { Fade } from 'react-reveal'

import logo_sms from '../images/logo-SMS-latinoamerica.jpg'
import logo_ifac from '../images/images_ifac.jpeg'
import CountUp from 'react-countup'

import TrackVisibility from 'react-on-screen'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import Layout from '../components/Layout'
import SectionVideo from '../components/SectionVideo'
import Button from '../components/common/Button.styled'
import Modal from '../components/common/Modal'
import Divider from '../components/common/Divider'
import YoutubeVideo from '../components/common/YoutubeVideo'
import Section from '../components/common/Section'
import Solutions from '../components/Solutions'
import Carousel from '../components/Carousel'

import {
  SectionContainer,
  CardContent,
  ItemContainer,
  TitleCard,
  TitleSection,
  ImageStyled,
  FillingBackground,
  GradientContainer,
} from '../components/common/Index.styled'
import {
  CarouselTransCell,
  ImageLazyLoad,
} from '../components/Carousel/Carousel.styled'
import ArticlePreview from '../components/ArticlePreview'
import NewsPreview from '../components/NewsPreview'
import logo_AS_blue from '../images/logo_as_blue.png'
import logo_AS_black from '../images/logo_as_black.png'
import icon_AS from '../images/icon_as.png'
import ContactForm from '../components/ContactForm'
import solutionsData from '../components/Solutions/utils'
import {
  Laptop,
  Warranty,
  Term,
  Home as HomeSVG,
  Alexa,
  Internet,
  World,
  Clock,
} from '../components/common/svgs'
import ListBooks from '../components/books/ListBooks'
import { Row, Col } from 'react-styled-flexboxgrid'

const Home = props => {
  const [modalIsOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }
  const posts = _.get(props, 'data.allContentfulNews.edges')
  const news = _.get(props, 'data.allContentfulMedia.edges')
  const images = _.get(props, 'data.allFile.edges')

  return (
    <Layout
      title={`AS Consulting Group - Despacho contable, fiscal y legal CDMX. ${
        props.location.state ? `| Nuestras Soluciones` : ''
      }`}
      description="En AS Consulting brindamos soluciones especializadas en materia contable, fiscal, legal, administrativa y financiera."
      {...props}
    >
      {/* Section 1 */}
      <SectionVideo>
        <Fade>
          <img src={logo_AS_blue} height={80} alt="Logo AS" />
        </Fade>
        <h1>
          <FormattedMessage id="index.section1_title" />
        </h1>
        <p>
          <FormattedMessage id="index.section1_description" />
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleModalOpen}>
            <FormattedMessage id="element.button_contactUs" />
          </Button>
        </div>
      </SectionVideo>

      <Divider light>
        <Carousel autoPlay>
          <ItemContainer to="/nosotros/#warranty">
            <Term style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title1" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#experience">
            <Warranty style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title5" />
          </ItemContainer>
          <ItemContainer to="/nosotros">
            <Laptop style={{ marginBottom: '1rem' }} />
            <div>
              <TrackVisibility>
                {({ isVisible }) =>
                  isVisible ? (
                    <CountUp
                      start={0}
                      end={500.0}
                      decimals={3}
                      delay={0}
                      duration={4}
                    >
                      {({ countUpRef }) => (
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          <span ref={countUpRef} />
                        </span>
                      )}
                    </CountUp>
                  ) : (
                    <span style={{ fontWeight: 'bold' }}>0</span>
                  )
                }
              </TrackVisibility>
              <FormattedHTMLMessage id="index.divider1_content_title2" />
            </div>
          </ItemContainer>
          <ItemContainer
            to="/nosotros/#solid_structure"
            style={{ marginBottom: '2rem' }}
          >
            <HomeSVG style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title8" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#tecnology">
            <Alexa style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title7" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#clients">
            <World style={{ marginBottom: '1rem' }} />
            <div>
              <TrackVisibility>
                {({ isVisible }) =>
                  isVisible ? (
                    <CountUp
                      start={0}
                      end={76}
                      delay={0}
                      duration={2.5}
                      formattingFn={n => `${n}%`}
                    >
                      {({ countUpRef }) => (
                        <span
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          <span ref={countUpRef} />
                        </span>
                      )}
                    </CountUp>
                  ) : (
                    <span style={{ fontWeight: 'bold' }}>76%</span>
                  )
                }
              </TrackVisibility>

              <FormattedHTMLMessage id="index.divider1_content_title3" />
            </div>
          </ItemContainer>
          <ItemContainer to="/nosotros/#languages">
            <Internet style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title6" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#sms">
            <div style={{ marginBottom: '1rem' }}>
              <img src={logo_sms} height={60} alt="Logo SMS" />
            </div>
            <FormattedHTMLMessage id="index.divider1_content_title4" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#sms">
            <Internet style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title9" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#sms">
            <HomeSVG style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title10" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#sms">
            <World style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title11" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#sms">
            <Clock style={{ marginBottom: '1rem' }} />
            <FormattedHTMLMessage id="index.divider1_content_title12" />
          </ItemContainer>
          <ItemContainer to="/nosotros/#sms">
            <div style={{ marginBottom: '1rem' }}>
              <img src={logo_ifac} height={60} alt="Logo IFAC" />
            </div>
            <FormattedHTMLMessage id="index.divider1_content_title13" />
          </ItemContainer>
        </Carousel>
      </Divider>

      {/* Section 2 */}
      <Section id="consulting-services">
        <TitleSection>
          <FormattedMessage id="solutions.title" />
        </TitleSection>
        <Solutions solutionsData={solutionsData} />
      </Section>

      <Divider border="right" align light>
        <FillingBackground />
        <div style={{ paddingRight: '2rem' }}>
          <h2>
            <FormattedMessage id="index.divider2_content_title" />
          </h2>
          <p style={{ fontSize: '1.2rem', maxWidth: '1100px' }}>
            <FormattedMessage id="index.divider2_content_description" />
          </p>
        </div>
        <Button onClick={handleModalOpen}>
          <FormattedMessage id="element.button_contactUs" />
        </Button>
      </Divider>

      {/* Section 3 */}
      <Section height="600px" backgroundImage="section2">
        <TitleSection black="true">
          <img
            src={icon_AS}
            height={55}
            alt="Icon AS Consulting"
            style={{ marginRight: '0.5rem' }}
          />
          <FormattedMessage id="blog.title" />
        </TitleSection>
        <Carousel>
          {posts.map(({ node }) => (
            <ArticlePreview
              key={node.slug}
              article={node}
              pathname="/noticias/"
            />
          ))}
        </Carousel>
      </Section>

      {/* Books  */}
      <Divider light>
        <TitleSection
          black="true"
          style={{ margin: '1rem auto', color: 'white' }}
        >
          <img
            src={icon_AS}
            height={55}
            alt="Icon AS Consulting"
            style={{ marginRight: '0.5rem' }}
          />
          <span>Books</span>
        </TitleSection>
        <Carousel autoPlay>
          <ListBooks />
        </Carousel>
      </Divider>

      <Divider>
        <Row middle="xs">
          <Col xs={12} md={2}>
            <Button onClick={handleModalOpen}>
              <FormattedMessage id="element.button_contactUs" />
            </Button>
          </Col>
          <Col xs={12} md={10}>
            <div style={{ paddingLeft: '2rem', textAlign: 'right' }}>
              <h2>
                <FormattedMessage id="index.divider3_content_title" />
              </h2>
              <p style={{ fontSize: '1.2rem' }}>
                <FormattedMessage id="index.divider3_content_description" />
              </p>
            </div>
          </Col>
        </Row>
      </Divider>

      {/* Section 4 */}
      <Section backgroundImage="section3">
        <SectionContainer>
          <YoutubeVideo video="Gf7S0ff5Y5E" autoplay="0" rel="0" modest="1" />
          <CardContent>
            <TitleCard textAlign="right">
              <FormattedMessage id="index.section4_container1_title" />
            </TitleCard>
            {_.times(3, Number).map(i => (
              <p key={i}>
                <FormattedMessage
                  id={`index.section4_container1_description.p${i + 1}`}
                />
              </p>
            ))}
            <ul>
              {_.times(9, Number).map(i => (
                <li key={i}>
                  <FormattedHTMLMessage
                    id={`index.section4_container1_description.list.item${i +
                      1}`}
                  />
                </li>
              ))}
            </ul>
          </CardContent>
        </SectionContainer>
        <SectionContainer>
          <CardContent>
            <TitleCard textAlign="left">
              <FormattedMessage id="index.section4_container2_title" />
            </TitleCard>
            {_.times(3, Number).map(i => (
              <p key={i}>
                <FormattedHTMLMessage
                  id={`index.section4_container2_description.p${i + 1}`}
                />
              </p>
            ))}
          </CardContent>
          <ImageStyled
            fixed={_.get(
              images.find(({ node }) => node.name === 'porque-elegirnos'),
              'node.childImageSharp.fixed'
            )}
          />
        </SectionContainer>
        <SectionContainer>
          <ImageStyled
            fixed={_.get(
              images.find(
                ({ node }) => node.name === 'garantia-servicios-contables'
              ),
              'node.childImageSharp.fixed'
            )}
          />

          <CardContent>
            <TitleCard textAlign="right">
              <FormattedMessage id="index.section4_container3_title" />
            </TitleCard>
            <p>
              <FormattedHTMLMessage id="index.section4_container3_description" />
            </p>
          </CardContent>
        </SectionContainer>
        <SectionContainer>
          <CardContent>
            <TitleCard textAlign="left">
              <FormattedMessage id="index.section4_container4_title" />
            </TitleCard>
            <p>
              <FormattedHTMLMessage id="index.section4_container4_description" />
            </p>
          </CardContent>
          <ImageStyled
            fixed={_.get(
              images.find(({ node }) => node.name === 'despachos-contables-df'),
              'node.childImageSharp.fixed'
            )}
          />
        </SectionContainer>

        <TitleSection>
          <img
            src={icon_AS}
            height={55}
            alt="Icon AS Consulting"
            style={{ marginRight: '0.5rem' }}
          />
          <FormattedMessage id="news.title" />
        </TitleSection>
        <NewsPreview news={news} />
      </Section>

      <Divider border="both" align>
        <FillingBackground top />
        <Button onClick={handleModalOpen}>
          <FormattedMessage id="element.button_advisory" />
        </Button>
        <h2>
          <FormattedMessage id="index.divider4_content_title" />
        </h2>
        <Button onClick={handleModalOpen}>
          <FormattedMessage id="element.button_contactUs" />
        </Button>
      </Divider>
      <Section height="150px">
        <GradientContainer />
        <Carousel autoPlay disableButtons>
          {_.map(_.times(10, String), (value, key) => (
            <CarouselTransCell key={key}>
              <ImageLazyLoad
                alt="Allies"
                src={require(`../images/allies/${key + 1}.png`)}
                srcSet={require(`../images/allies/${key + 1}.png`)}
                data-flickity-lazyload={require(`../images/allies/${key +
                  1}.png`)}
              />
            </CarouselTransCell>
          ))}
        </Carousel>
      </Section>
      <Modal isOpen={modalIsOpen} onRequestClose={handleModalClose}>
        <img
          src={logo_AS_black}
          height={55}
          alt="Icon AS Consulting"
          style={{ marginRight: '0.5rem' }}
        />
        <ContactForm color="blue" actionAfterSubmit={handleModalClose} />
      </Modal>
      <NotificationContainer />
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulNews(sort: { fields: [publishDate], order: DESC }, limit: 6) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulMedia(sort: { fields: [publishDate], order: DESC }, limit: 6) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, background: "rgb:ffffff") {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "porque-elegirnos.jpg"
            "garantia-servicios-contables.jpg"
            "despachos-contables-df.jpg"
          ]
        }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fixed(height: 220) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
