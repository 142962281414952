import React from 'react'
import { Link } from 'gatsby'

import StackGrid from 'react-stack-grid'
import {
  ContainerImage,
  ContentDetails,
  CustomImage,
  TitleNew,
} from './NewPreview.styled'

const NewsPreview = ({ news }) => {
  return (
    <StackGrid columnWidth={320}>
      {news.map(({ node }) => (
        <Link to={`/medios/${node.slug}`} key={node.slug}>
          <ContainerImage>
            <CustomImage alt="" fluid={node.heroImage.fluid} />
            <ContentDetails>
              <TitleNew>{node.title}</TitleNew>
            </ContentDetails>
          </ContainerImage>
        </Link>
      ))}
    </StackGrid>
  )
}

export default NewsPreview
