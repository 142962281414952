import React from 'react'
import {
  SectionRowStyled,
  SectionColStyled,
  Video,
  ContainerVideo,
} from './SectionVideo.styled'
import header_video from '../../videos/mexico_city_video.mp4'

const Section = ({ children, type }) => (
  <SectionRowStyled center="xs" middle="xs">
    <ContainerVideo>
      <Video
        autoPlay
        loop
        muted
        src={header_video}
        type="video/mp4"
        playsInline
      >
        Your browser does not support the video tag. I suggest you upgrade your
        browser.
      </Video>
    </ContainerVideo>
    <SectionColStyled xs={11} lg={8}>
      {children}
    </SectionColStyled>
  </SectionRowStyled>
)

export default Section
