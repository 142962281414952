import React from 'react'
import _ from 'lodash'
import Img from 'gatsby-image'
import { ItemContainer } from '../../components/common/Index.styled'
import { useStaticQuery, graphql } from 'gatsby'
import { bookItems } from './utils'

const ListBooks = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "books" } }) {
        edges {
          node {
            name
            childImageSharp {
              fixed(height: 375) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return bookItems.map(({ key, href = '' }) => (
    <ItemContainer key={key} href={href} as="a" target="_blank">
      <Img
        fixed={_.get(
          edges.find(({ node }) => node.name === key),
          'node.childImageSharp.fixed'
        )}
        style={{ height: 200, width: '100%' }}
        imgStyle={{ objectFit: 'contain' }}
      />
    </ItemContainer>
  ))
}

export default ListBooks
