import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import hex2rgba from '../../utils/hex2rgba'

import snap_video_background from '../../images/snap_video_background.jpg'
import { BP_MEDIUM, BP_SMALL, BP_SMALLEST } from '../../styles/theme';

const SectionRowStyled = styled(Row)`
  overflow:hidden;
  position: relative;
  min-height: 100vh;
  color: ${props => props.theme.textLight};
  font-size: 1.2rem;
  @media only screen and (max-width: ${BP_SMALL}) {
    height: 900px;
  }
`
const ContainerVideo = styled.div`
  width: 100%;
  background-size: cover;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: ${props => hex2rgba(props.theme.primary, 0.6)};
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    /* left: 10%; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-image: url(${snap_video_background});
  }
`

const Video = styled.video`
  left: 50%;
  max-height: 1500px;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`
const SectionColStyled = styled(Col)`
  text-align: center;
  position: absolute;
  z-index: 10;
  & > img {
    @media only screen and (max-width: ${BP_MEDIUM}) {
      display: none;
    }
  }
  @media only screen and (max-width: ${BP_SMALLEST}) {
    margin-top: 0.5rem;
  }
`

export { SectionRowStyled, SectionColStyled, Video, ContainerVideo }
